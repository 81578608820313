import { gql } from '@urql/core';

export const FUND_DRAWS = gql`
    query FundDraws($fund_id: ID) {
      fundDraws(fund_id: $fund_id) {
        id,
        fund_id,
        uuid,
        banking_name,
        iban,
        status,
        amount,
        amount_paid,
        projected_return,
        person {
          id
          name
        },
        funder {
          id
          status
          name
          type
          coc_number
          ibans
          ubo_count
        },
        audits {
          action,
          created_at,
          id,
          mutations
        },
        returns,
        payout_sum,
        created_at,
        updated_at,
        documentContext {
          documentOwners {
            owner {
              ... on Person {
                id
                name
                __typename
              }
              ... on Company {
                id
                name
                coc_number
                __typename
              }
            }
            items {
              id
              key
              name
              maxSize
              records {
                asset {
                  absolute_url
                  created_at
                  extension
                  filename
                  id
                  updated_at
                  filesize {
                    raw
                    human
                  }
                }
                created_at
                id
                name
                updated_at
                downloadable_for_customer
              }
              template {
                absolute_url
              }
              type
              visible_for_customer
              deletable_for_customer
              sign_link
            }
          }
        }
      }
    }
`;

export const FUNDS = gql`
    query Funds {
      funds {
        id
        name
        key
        type
        start_date
        end_date
        draw_start_date
        registration_end_date
        communication_registration_end_date
        return_start_date
        visible_for_customer
        iban_name
        iban
        return_percentage
        fund_conditions {
          id
          absolute_url
        }
        brochure {
          id
          absolute_url
        }
        status
        projected_return
        potential_total
        page_status
      }
    }
`;

export const FUND = gql`
  query Fund($id: ID!) {
    fund(id: $id) {
      id
      name
      key
      type
      start_date
      end_date
      draw_start_date
      registration_end_date
      communication_registration_end_date
      return_start_date
      visible_for_customer
      iban_name
      iban
      return_percentage
      fund_conditions {
        id
        absolute_url
      }
      brochure {
        id
        absolute_url
      }
      status
      projected_return
      potential_total
      page_status
    }
  }
`;

export const FUND_REGISTRATIONS = gql`
  query FundRegistrations {
    fundRegistrations {
      id
      fund_id
      status
      investor_type
      investor {
        ... on Person {
          id
          name
          __typename
        }
        ... on Company {
          id
          name
          coc_number
          __typename
        }
      }
      status
      signed_name
      fund_total_investment
      commitment_agreement_signed
      reason
      iban_name
      iban
      bic
    }
  }
`;


export const FUND_OPTION = gql`
  query FundOption($hash: String!, $property_id: ID!, $type: String!) {
    fundOption(hash: $hash, property_id: $property_id, type: $type) {
     message
     status
    }
  }
`;

export const SUBMIT_FUND_OPTION = gql`
  mutation submitFundOption($hash: String!, $property_id: ID!, $type: String!) {
    submitFundOption(input: {hash: $hash, property_id: $property_id, type: $type}) {
      message
      status
    }
  }
`;
