import {
  faAngleDown,
  faArrowUpRightFromSquare,
  faXmark,
  faCheck,
  faChevronDown,
  faChevronUp,
  faBuildingLock,
  faPlus,
  faQuestion,
  faStar,
  faSpinner,
  faTimer,
  faTrashCan,
  faPen,
  faCircleExclamation,
  faObjectsColumn,
  faDiagramCells,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faAnglesLeft,
  faAnglesRight,
  faBars,
  faBriefcase,
  faBuilding,
  faClock,
  faDownload,
  faEllipsis,
  faFile,
  faFilePdf,
  faFileArrowDown,
  faLoader,
  faPercent,
  faSack,
  faSquareExclamation,
  faThumbsUp,
  faUser,
  faRoute,
} from '@fortawesome/pro-duotone-svg-icons';

export const getFontAwesomeIcons = () => [
  faAngleDown,
  faAnglesLeft,
  faAnglesRight,
  faArrowUpRightFromSquare,
  faBars,
  faBriefcase,
  faBuilding,
  faCheck,
  faChevronDown,
  faChevronUp,
  faClock,
  faDownload,
  faEllipsis,
  faFile,
  faFilePdf,
  faFileArrowDown,
  faLoader,
  faPlus,
  faSquareExclamation,
  faCircleExclamation,
  faRoute,
  faPercent,
  faSack,
  faThumbsUp,
  faUser,
  faXmark,
  faQuestion,
  faBuildingLock,
  faStar,
  faTimer,
  faTrashCan,
  faSpinner,
  faPen,
  faObjectsColumn,
  faDiagramCells,
];
