export enum FundStatuses {
  CONCEPT = 'CONCEPT',
  REGISTRATION = 'REGISTRATION',
  COLLECT = 'COLLECT',
  ACTIVE = 'ACTIVE',
  STOPPED = 'STOPPED',
  DELETED = 'DELETED',
}

export enum FundPageStatuses {
  PARTICIPATION = 'PARTICIPATION',
  DOCUMENTS = 'DOCUMENTS',
  SUMMARY = 'SUMMARY',
  SIGN = 'SIGN',
  TRANSFER = 'TRANSFER',
  NOT_ALLOWED = 'NOT_ALLOWED',
}
