import { default as _91choice_93tSSkFyHYb4Meta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/aanbod-optie/[propertyId]/[hash]/[choice].vue?macro=true";
import { default as indexN71Ot4jvphMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/aanbod/index.vue?macro=true";
import { default as indexVpLhFbKxItMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/fonds-optie/[property_id]/[hash]/[type]/index.vue?macro=true";
import { default as indexsIRtcn6EfQMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/index.vue?macro=true";
import { default as indexUHjpYfEMvyMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/login/index.vue?macro=true";
import { default as reset_45wachtwoordGnWLCPZKq4Meta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/login/reset-wachtwoord.vue?macro=true";
import { default as wachtwoord_45vergetenpN440sqGVRMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/login/wachtwoord-vergeten.vue?macro=true";
import { default as indexdGITR7KNQbMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mijn-aanvragen/[offerNumber]/herkomst/index.vue?macro=true";
import { default as indexCR3gxOHDaMMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mijn-aanvragen/[offerNumber]/index.vue?macro=true";
import { default as indexMC6MkluDlNMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mijn-aanvragen/index.vue?macro=true";
import { default as indexLzfDOb1OzCMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mijn-organisaties/[companyId]/iban-overzicht/index.vue?macro=true";
import { default as indexrw9U449XikMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mijn-organisaties/bedrijfs-gegevens/index.vue?macro=true";
import { default as index3Qidxh9vfpMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mijn-organisaties/index.vue?macro=true";
import { default as index5EZCnl0Ir1Meta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mijn-partnerplan/[token]/[choice]/index.vue?macro=true";
import { default as indexUtQ1fJ9yYEMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mijn-partnerplan/index.vue?macro=true";
import { default as index0UnATtmw8VMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mijn-profiel/digitaal-identificeren/index.vue?macro=true";
import { default as indexEsdnrN8oqKMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mijn-profiel/iban-overzicht/index.vue?macro=true";
import { default as indexDhuELi0DB3Meta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mijn-profiel/index.vue?macro=true";
import { default as index9I03ky6YZPMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mijn-profiel/naw-gegevens/index.vue?macro=true";
import { default as indexsbyYsDFjp3Meta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mobile/index.vue?macro=true";
import { default as indexidVE2B5mn4Meta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mogelijk-fonds/[id]/deelname/index.vue?macro=true";
import { default as indexMnsZUrLAq6Meta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mogelijk-fonds/[id]/documenten/index.vue?macro=true";
import { default as indexySUWs8IYcaMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mogelijk-fonds/[id]/index.vue?macro=true";
import { default as indexLoPe1Zg0kvMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mogelijk-fonds/[id]/inschrijving/index.vue?macro=true";
import { default as indexKDEi9Ihtb1Meta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mogelijk-fonds/[id]/ondertekenen/index.vue?macro=true";
import { default as index5peEScOsEwMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mogelijk-fonds/[id]/overmaken/index.vue?macro=true";
import { default as index1n8P3fy4pbMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mogelijk-fonds/index.vue?macro=true";
import { default as errorC5dKGlpg1IMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/ockto/[hash]/error.vue?macro=true";
import { default as indexcbIiWh4ccvMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/ockto/[hash]/index.vue?macro=true";
import { default as qrcodemCwIISVXWSMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/ockto/[hash]/qrcode.vue?macro=true";
import { default as succesF3Tceg668mMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/ockto/[hash]/succes.vue?macro=true";
import { default as _91token_93c3gzhlBRCqMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/onboarding/[signing_id]/[token].vue?macro=true";
import { default as _91slug_93S60amjqjHYMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/onboarding/[slug].vue?macro=true";
import { default as aanvraaglmG1SX3ZKIMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/onboarding/aanvraag.vue?macro=true";
import { default as _91experience_93DPXYStb9YqMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/onboarding/bedankt/[experience].vue?macro=true";
import { default as _91token_932vZIJ6XNZWMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/onfido/[token].vue?macro=true";
import { default as indexIAvjqMfz6YMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/privacy-verklaring/index.vue?macro=true";
import { default as _91fundKey_93Rjem091wVpMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/sso/[exchangeToken]/[fundKey].vue?macro=true";
import { default as aanvraagMUyXMzDbJgMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/sso/[exchangeToken]/onboarding/aanvraag.vue?macro=true";
import { default as index71HejSG43BMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/sso/[exchangeToken]/onboarding/index.vue?macro=true";
import { default as _91id_939e74Bt8U0GMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/sso/[token]/[id].vue?macro=true";
import { default as indexKtAjNIKRdkMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/sso/[token]/index.vue?macro=true";
import { default as _91hash_93lA1OtoT9OQMeta } from "/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/verzekeringen/[hash].vue?macro=true";
export default [
  {
    name: _91choice_93tSSkFyHYb4Meta?.name ?? "aanbod-optie-propertyId-hash-choice",
    path: _91choice_93tSSkFyHYb4Meta?.path ?? "/aanbod-optie/:propertyId()/:hash()/:choice()",
    meta: _91choice_93tSSkFyHYb4Meta || {},
    alias: _91choice_93tSSkFyHYb4Meta?.alias || [],
    redirect: _91choice_93tSSkFyHYb4Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/aanbod-optie/[propertyId]/[hash]/[choice].vue").then(m => m.default || m)
  },
  {
    name: indexN71Ot4jvphMeta?.name ?? "aanbod",
    path: indexN71Ot4jvphMeta?.path ?? "/aanbod",
    meta: indexN71Ot4jvphMeta || {},
    alias: indexN71Ot4jvphMeta?.alias || [],
    redirect: indexN71Ot4jvphMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/aanbod/index.vue").then(m => m.default || m)
  },
  {
    name: indexVpLhFbKxItMeta?.name ?? "fonds-optie-property_id-hash-type",
    path: indexVpLhFbKxItMeta?.path ?? "/fonds-optie/:property_id()/:hash()/:type()",
    meta: indexVpLhFbKxItMeta || {},
    alias: indexVpLhFbKxItMeta?.alias || [],
    redirect: indexVpLhFbKxItMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/fonds-optie/[property_id]/[hash]/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: indexsIRtcn6EfQMeta?.name ?? "index",
    path: indexsIRtcn6EfQMeta?.path ?? "/",
    meta: indexsIRtcn6EfQMeta || {},
    alias: indexsIRtcn6EfQMeta?.alias || [],
    redirect: indexsIRtcn6EfQMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexUHjpYfEMvyMeta?.name ?? "login",
    path: indexUHjpYfEMvyMeta?.path ?? "/login",
    meta: indexUHjpYfEMvyMeta || {},
    alias: indexUHjpYfEMvyMeta?.alias || [],
    redirect: indexUHjpYfEMvyMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: reset_45wachtwoordGnWLCPZKq4Meta?.name ?? "login-reset-wachtwoord",
    path: reset_45wachtwoordGnWLCPZKq4Meta?.path ?? "/login/reset-wachtwoord",
    meta: reset_45wachtwoordGnWLCPZKq4Meta || {},
    alias: reset_45wachtwoordGnWLCPZKq4Meta?.alias || [],
    redirect: reset_45wachtwoordGnWLCPZKq4Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/login/reset-wachtwoord.vue").then(m => m.default || m)
  },
  {
    name: wachtwoord_45vergetenpN440sqGVRMeta?.name ?? "login-wachtwoord-vergeten",
    path: wachtwoord_45vergetenpN440sqGVRMeta?.path ?? "/login/wachtwoord-vergeten",
    meta: wachtwoord_45vergetenpN440sqGVRMeta || {},
    alias: wachtwoord_45vergetenpN440sqGVRMeta?.alias || [],
    redirect: wachtwoord_45vergetenpN440sqGVRMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/login/wachtwoord-vergeten.vue").then(m => m.default || m)
  },
  {
    name: indexdGITR7KNQbMeta?.name ?? "mijn-aanvragen-offerNumber-herkomst",
    path: indexdGITR7KNQbMeta?.path ?? "/mijn-aanvragen/:offerNumber()/herkomst",
    meta: indexdGITR7KNQbMeta || {},
    alias: indexdGITR7KNQbMeta?.alias || [],
    redirect: indexdGITR7KNQbMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mijn-aanvragen/[offerNumber]/herkomst/index.vue").then(m => m.default || m)
  },
  {
    name: indexCR3gxOHDaMMeta?.name ?? "mijn-aanvragen-offerNumber",
    path: indexCR3gxOHDaMMeta?.path ?? "/mijn-aanvragen/:offerNumber()",
    meta: indexCR3gxOHDaMMeta || {},
    alias: indexCR3gxOHDaMMeta?.alias || [],
    redirect: indexCR3gxOHDaMMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mijn-aanvragen/[offerNumber]/index.vue").then(m => m.default || m)
  },
  {
    name: indexMC6MkluDlNMeta?.name ?? "mijn-aanvragen",
    path: indexMC6MkluDlNMeta?.path ?? "/mijn-aanvragen",
    meta: indexMC6MkluDlNMeta || {},
    alias: indexMC6MkluDlNMeta?.alias || [],
    redirect: indexMC6MkluDlNMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mijn-aanvragen/index.vue").then(m => m.default || m)
  },
  {
    name: indexLzfDOb1OzCMeta?.name ?? "mijn-organisaties-companyId-iban-overzicht",
    path: indexLzfDOb1OzCMeta?.path ?? "/mijn-organisaties/:companyId()/iban-overzicht",
    meta: indexLzfDOb1OzCMeta || {},
    alias: indexLzfDOb1OzCMeta?.alias || [],
    redirect: indexLzfDOb1OzCMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mijn-organisaties/[companyId]/iban-overzicht/index.vue").then(m => m.default || m)
  },
  {
    name: indexrw9U449XikMeta?.name ?? "mijn-organisaties-bedrijfs-gegevens",
    path: indexrw9U449XikMeta?.path ?? "/mijn-organisaties/bedrijfs-gegevens",
    meta: indexrw9U449XikMeta || {},
    alias: indexrw9U449XikMeta?.alias || [],
    redirect: indexrw9U449XikMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mijn-organisaties/bedrijfs-gegevens/index.vue").then(m => m.default || m)
  },
  {
    name: index3Qidxh9vfpMeta?.name ?? "mijn-organisaties",
    path: index3Qidxh9vfpMeta?.path ?? "/mijn-organisaties",
    meta: index3Qidxh9vfpMeta || {},
    alias: index3Qidxh9vfpMeta?.alias || [],
    redirect: index3Qidxh9vfpMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mijn-organisaties/index.vue").then(m => m.default || m)
  },
  {
    name: index5EZCnl0Ir1Meta?.name ?? "mijn-partnerplan-token-choice",
    path: index5EZCnl0Ir1Meta?.path ?? "/mijn-partnerplan/:token()/:choice()",
    meta: index5EZCnl0Ir1Meta || {},
    alias: index5EZCnl0Ir1Meta?.alias || [],
    redirect: index5EZCnl0Ir1Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mijn-partnerplan/[token]/[choice]/index.vue").then(m => m.default || m)
  },
  {
    name: indexUtQ1fJ9yYEMeta?.name ?? "mijn-partnerplan",
    path: indexUtQ1fJ9yYEMeta?.path ?? "/mijn-partnerplan",
    meta: indexUtQ1fJ9yYEMeta || {},
    alias: indexUtQ1fJ9yYEMeta?.alias || [],
    redirect: indexUtQ1fJ9yYEMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mijn-partnerplan/index.vue").then(m => m.default || m)
  },
  {
    name: index0UnATtmw8VMeta?.name ?? "mijn-profiel-digitaal-identificeren",
    path: index0UnATtmw8VMeta?.path ?? "/mijn-profiel/digitaal-identificeren",
    meta: index0UnATtmw8VMeta || {},
    alias: index0UnATtmw8VMeta?.alias || [],
    redirect: index0UnATtmw8VMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mijn-profiel/digitaal-identificeren/index.vue").then(m => m.default || m)
  },
  {
    name: indexEsdnrN8oqKMeta?.name ?? "mijn-profiel-iban-overzicht",
    path: indexEsdnrN8oqKMeta?.path ?? "/mijn-profiel/iban-overzicht",
    meta: indexEsdnrN8oqKMeta || {},
    alias: indexEsdnrN8oqKMeta?.alias || [],
    redirect: indexEsdnrN8oqKMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mijn-profiel/iban-overzicht/index.vue").then(m => m.default || m)
  },
  {
    name: indexDhuELi0DB3Meta?.name ?? "mijn-profiel",
    path: indexDhuELi0DB3Meta?.path ?? "/mijn-profiel",
    meta: indexDhuELi0DB3Meta || {},
    alias: indexDhuELi0DB3Meta?.alias || [],
    redirect: indexDhuELi0DB3Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mijn-profiel/index.vue").then(m => m.default || m)
  },
  {
    name: index9I03ky6YZPMeta?.name ?? "mijn-profiel-naw-gegevens",
    path: index9I03ky6YZPMeta?.path ?? "/mijn-profiel/naw-gegevens",
    meta: index9I03ky6YZPMeta || {},
    alias: index9I03ky6YZPMeta?.alias || [],
    redirect: index9I03ky6YZPMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mijn-profiel/naw-gegevens/index.vue").then(m => m.default || m)
  },
  {
    name: indexsbyYsDFjp3Meta?.name ?? "mobile",
    path: indexsbyYsDFjp3Meta?.path ?? "/mobile",
    meta: indexsbyYsDFjp3Meta || {},
    alias: indexsbyYsDFjp3Meta?.alias || [],
    redirect: indexsbyYsDFjp3Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mobile/index.vue").then(m => m.default || m)
  },
  {
    name: indexidVE2B5mn4Meta?.name ?? "mogelijk-fonds-id-deelname",
    path: indexidVE2B5mn4Meta?.path ?? "/mogelijk-fonds/:id()/deelname",
    meta: indexidVE2B5mn4Meta || {},
    alias: indexidVE2B5mn4Meta?.alias || [],
    redirect: indexidVE2B5mn4Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mogelijk-fonds/[id]/deelname/index.vue").then(m => m.default || m)
  },
  {
    name: indexMnsZUrLAq6Meta?.name ?? "mogelijk-fonds-id-documenten",
    path: indexMnsZUrLAq6Meta?.path ?? "/mogelijk-fonds/:id()/documenten",
    meta: indexMnsZUrLAq6Meta || {},
    alias: indexMnsZUrLAq6Meta?.alias || [],
    redirect: indexMnsZUrLAq6Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mogelijk-fonds/[id]/documenten/index.vue").then(m => m.default || m)
  },
  {
    name: indexySUWs8IYcaMeta?.name ?? "mogelijk-fonds-id",
    path: indexySUWs8IYcaMeta?.path ?? "/mogelijk-fonds/:id()",
    meta: indexySUWs8IYcaMeta || {},
    alias: indexySUWs8IYcaMeta?.alias || [],
    redirect: indexySUWs8IYcaMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mogelijk-fonds/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexLoPe1Zg0kvMeta?.name ?? "mogelijk-fonds-id-inschrijving",
    path: indexLoPe1Zg0kvMeta?.path ?? "/mogelijk-fonds/:id()/inschrijving",
    meta: indexLoPe1Zg0kvMeta || {},
    alias: indexLoPe1Zg0kvMeta?.alias || [],
    redirect: indexLoPe1Zg0kvMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mogelijk-fonds/[id]/inschrijving/index.vue").then(m => m.default || m)
  },
  {
    name: indexKDEi9Ihtb1Meta?.name ?? "mogelijk-fonds-id-ondertekenen",
    path: indexKDEi9Ihtb1Meta?.path ?? "/mogelijk-fonds/:id()/ondertekenen",
    meta: indexKDEi9Ihtb1Meta || {},
    alias: indexKDEi9Ihtb1Meta?.alias || [],
    redirect: indexKDEi9Ihtb1Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mogelijk-fonds/[id]/ondertekenen/index.vue").then(m => m.default || m)
  },
  {
    name: index5peEScOsEwMeta?.name ?? "mogelijk-fonds-id-overmaken",
    path: index5peEScOsEwMeta?.path ?? "/mogelijk-fonds/:id()/overmaken",
    meta: index5peEScOsEwMeta || {},
    alias: index5peEScOsEwMeta?.alias || [],
    redirect: index5peEScOsEwMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mogelijk-fonds/[id]/overmaken/index.vue").then(m => m.default || m)
  },
  {
    name: index1n8P3fy4pbMeta?.name ?? "mogelijk-fonds",
    path: index1n8P3fy4pbMeta?.path ?? "/mogelijk-fonds",
    meta: index1n8P3fy4pbMeta || {},
    alias: index1n8P3fy4pbMeta?.alias || [],
    redirect: index1n8P3fy4pbMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/mogelijk-fonds/index.vue").then(m => m.default || m)
  },
  {
    name: errorC5dKGlpg1IMeta?.name ?? "ockto-hash-error",
    path: errorC5dKGlpg1IMeta?.path ?? "/ockto/:hash()/error",
    meta: errorC5dKGlpg1IMeta || {},
    alias: errorC5dKGlpg1IMeta?.alias || [],
    redirect: errorC5dKGlpg1IMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/ockto/[hash]/error.vue").then(m => m.default || m)
  },
  {
    name: indexcbIiWh4ccvMeta?.name ?? "ockto-hash",
    path: indexcbIiWh4ccvMeta?.path ?? "/ockto/:hash()",
    meta: indexcbIiWh4ccvMeta || {},
    alias: indexcbIiWh4ccvMeta?.alias || [],
    redirect: indexcbIiWh4ccvMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/ockto/[hash]/index.vue").then(m => m.default || m)
  },
  {
    name: qrcodemCwIISVXWSMeta?.name ?? "ockto-hash-qrcode",
    path: qrcodemCwIISVXWSMeta?.path ?? "/ockto/:hash()/qrcode",
    meta: qrcodemCwIISVXWSMeta || {},
    alias: qrcodemCwIISVXWSMeta?.alias || [],
    redirect: qrcodemCwIISVXWSMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/ockto/[hash]/qrcode.vue").then(m => m.default || m)
  },
  {
    name: succesF3Tceg668mMeta?.name ?? "ockto-hash-succes",
    path: succesF3Tceg668mMeta?.path ?? "/ockto/:hash()/succes",
    meta: succesF3Tceg668mMeta || {},
    alias: succesF3Tceg668mMeta?.alias || [],
    redirect: succesF3Tceg668mMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/ockto/[hash]/succes.vue").then(m => m.default || m)
  },
  {
    name: _91token_93c3gzhlBRCqMeta?.name ?? "onboarding-signing_id-token",
    path: _91token_93c3gzhlBRCqMeta?.path ?? "/onboarding/:signing_id()/:token()",
    meta: _91token_93c3gzhlBRCqMeta || {},
    alias: _91token_93c3gzhlBRCqMeta?.alias || [],
    redirect: _91token_93c3gzhlBRCqMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/onboarding/[signing_id]/[token].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93S60amjqjHYMeta?.name ?? "onboarding-slug",
    path: _91slug_93S60amjqjHYMeta?.path ?? "/onboarding/:slug()",
    meta: _91slug_93S60amjqjHYMeta || {},
    alias: _91slug_93S60amjqjHYMeta?.alias || [],
    redirect: _91slug_93S60amjqjHYMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/onboarding/[slug].vue").then(m => m.default || m)
  },
  {
    name: aanvraaglmG1SX3ZKIMeta?.name ?? "onboarding-aanvraag",
    path: aanvraaglmG1SX3ZKIMeta?.path ?? "/onboarding/aanvraag",
    meta: aanvraaglmG1SX3ZKIMeta || {},
    alias: aanvraaglmG1SX3ZKIMeta?.alias || [],
    redirect: aanvraaglmG1SX3ZKIMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/onboarding/aanvraag.vue").then(m => m.default || m)
  },
  {
    name: _91experience_93DPXYStb9YqMeta?.name ?? "onboarding-bedankt-experience",
    path: _91experience_93DPXYStb9YqMeta?.path ?? "/onboarding/bedankt/:experience()",
    meta: _91experience_93DPXYStb9YqMeta || {},
    alias: _91experience_93DPXYStb9YqMeta?.alias || [],
    redirect: _91experience_93DPXYStb9YqMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/onboarding/bedankt/[experience].vue").then(m => m.default || m)
  },
  {
    name: _91token_932vZIJ6XNZWMeta?.name ?? "onfido-token",
    path: _91token_932vZIJ6XNZWMeta?.path ?? "/onfido/:token()",
    meta: _91token_932vZIJ6XNZWMeta || {},
    alias: _91token_932vZIJ6XNZWMeta?.alias || [],
    redirect: _91token_932vZIJ6XNZWMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/onfido/[token].vue").then(m => m.default || m)
  },
  {
    name: indexIAvjqMfz6YMeta?.name ?? "privacy-verklaring",
    path: indexIAvjqMfz6YMeta?.path ?? "/privacy-verklaring",
    meta: indexIAvjqMfz6YMeta || {},
    alias: indexIAvjqMfz6YMeta?.alias || [],
    redirect: indexIAvjqMfz6YMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/privacy-verklaring/index.vue").then(m => m.default || m)
  },
  {
    name: _91fundKey_93Rjem091wVpMeta?.name ?? "sso-exchangeToken-fundKey",
    path: _91fundKey_93Rjem091wVpMeta?.path ?? "/sso/:exchangeToken()/:fundKey()",
    meta: _91fundKey_93Rjem091wVpMeta || {},
    alias: _91fundKey_93Rjem091wVpMeta?.alias || [],
    redirect: _91fundKey_93Rjem091wVpMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/sso/[exchangeToken]/[fundKey].vue").then(m => m.default || m)
  },
  {
    name: aanvraagMUyXMzDbJgMeta?.name ?? "sso-exchangeToken-onboarding-aanvraag",
    path: aanvraagMUyXMzDbJgMeta?.path ?? "/sso/:exchangeToken()/onboarding/aanvraag",
    meta: aanvraagMUyXMzDbJgMeta || {},
    alias: aanvraagMUyXMzDbJgMeta?.alias || [],
    redirect: aanvraagMUyXMzDbJgMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/sso/[exchangeToken]/onboarding/aanvraag.vue").then(m => m.default || m)
  },
  {
    name: index71HejSG43BMeta?.name ?? "sso-exchangeToken-onboarding",
    path: index71HejSG43BMeta?.path ?? "/sso/:exchangeToken()/onboarding",
    meta: index71HejSG43BMeta || {},
    alias: index71HejSG43BMeta?.alias || [],
    redirect: index71HejSG43BMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/sso/[exchangeToken]/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_939e74Bt8U0GMeta?.name ?? "sso-token-id",
    path: _91id_939e74Bt8U0GMeta?.path ?? "/sso/:token()/:id()",
    meta: _91id_939e74Bt8U0GMeta || {},
    alias: _91id_939e74Bt8U0GMeta?.alias || [],
    redirect: _91id_939e74Bt8U0GMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/sso/[token]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexKtAjNIKRdkMeta?.name ?? "sso-token",
    path: indexKtAjNIKRdkMeta?.path ?? "/sso/:token()",
    meta: indexKtAjNIKRdkMeta || {},
    alias: indexKtAjNIKRdkMeta?.alias || [],
    redirect: indexKtAjNIKRdkMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/sso/[token]/index.vue").then(m => m.default || m)
  },
  {
    name: _91hash_93lA1OtoT9OQMeta?.name ?? "verzekeringen-hash",
    path: _91hash_93lA1OtoT9OQMeta?.path ?? "/verzekeringen/:hash()",
    meta: _91hash_93lA1OtoT9OQMeta || {},
    alias: _91hash_93lA1OtoT9OQMeta?.alias || [],
    redirect: _91hash_93lA1OtoT9OQMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20240903131935/packages/investors/pages/verzekeringen/[hash].vue").then(m => m.default || m)
  }
]