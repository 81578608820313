import { defineNuxtPlugin } from 'nuxt/app';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { useAuthStore } from '@stores/useAuthStore';

export default defineNuxtPlugin(nuxtApp => {
  const { $config } = nuxtApp;
  const auth = useAuthStore();

  window.Pusher = Pusher;

  const echo = new Echo({
    broadcaster: 'pusher',
    key: $config.public.pusherAppKey,
    cluster: 'eu',
    wsHost: $config.public.pusherAppHost,
    forceTLS: true,
    disableStats: false,
    authEndpoint: '/broadcasting/auth',
    auth: {
      headers: {
        Authorization: auth && auth.accessToken
          ? `Bearer ${auth.accessToken}`
          : undefined,
      },
    },
    enabledTransports: ['ws', 'wss'],
  });
  nuxtApp.provide('echo', echo);
});
