import { createPersistedStatePlugin } from 'pinia-plugin-persistedstate-2';
import { parseCookies, setCookie, destroyCookie } from 'nookies';

export default defineNuxtPlugin(nuxtApp => {
  (nuxtApp.$pinia as any).use(
    createPersistedStatePlugin({
      // plugin options goes here
      storage: {
        getItem: key => {
          // See https://nuxtjs.org/guide/plugins/#using-process-flags
          if (process.server) {
            return useCookie(nuxtApp.ssrContext?.toString() ?? '');
          }
          const cookies = parseCookies();
          return cookies[key];
        },
        // Please see https://github.com/js-cookie/js-cookie#json, on how to handle JSON.
        setItem: (key, value) => {
          setCookie(null, key, value, {
            maxAge: 30 * 24 * 60 * 60,
            secure: process.env.NODE_ENV === 'production',
            path: '/',
          });
        },
        removeItem: key => {
          destroyCookie(null, key);
        },
      },
    }),
  );
});
