import { gql } from '@urql/core';

export const ME_QUERY = gql`
  query Me {
    me {
      id
      name
      first_name
      last_name
      infix
      gender
      date_of_birth
      place_of_birth
      country_of_birth
      onfido_checked
      onfido_status
      onfido_hash
      is_mogelijk_partner
      addresses {
        main_address
        street
        house_number
        house_number_addition
        postal_code
        city
      }
      documents {
        id
        key
        name
        type
        visible_for_customer
        onfido_status
        records {
          asset {
            absolute_url
            created_at
            extension
            filename
            id
            updated_at
            filesize {
              raw
              human
            }
          }
          created_at
          id
          name
          updated_at
          downloadable_for_customer
        }
      }
      documentContext {
        documentOwners {
          owner {
            ... on Person {
              id
              name
            }
            ... on Company {
              id
              name
              coc_number
              __typename
            }
          }
          items {
            id
            key
            name
            type
            visible_for_customer
            deletable_for_customer
            onfido_status
            maxSize
            template {
              absolute_url
              created_at
              extension
              filename
              id
            }
            records {
              asset {
                absolute_url
                created_at
                extension
                filename
                id
                updated_at
                filesize {
                  raw
                  human
                }
              }
              created_at
              id
              name
              updated_at
              downloadable_for_customer
            }
          }
        }
        title
        fund_key
        show
      }
      roles {
        id
        type
        status
        account_view_ids
        role_data {
          ... on InitialPerson {
            ibans
            emails {
              email
              type
            }
            phones {
              phone
              type
            }
          }
          ... on Investor {
            ibans
            emails {
              email
              type
            }
            phones {
              phone
              type
            }
          }
          ... on FundInvestor {
            ibans
            emails {
              email
              type
            }
            phones {
              phone
              type
            }
          }
          ... on Borrower {
            ibans
            emails {
              email
              type
            }
            phones {
              phone
              type
            }
          }
          ... on Advisor {
            ibans
            emails {
              email
              type
            }
            phones {
              phone
              type
            }
          }
        }
      }
      companies {
        id
        status
        name
        type
        coc_number
        ibans
        ubo_count
      }
      funders {
        id
        status
        name
        type
        coc_number
        ibans
        ubo_count
      }
    }
  }
`;
