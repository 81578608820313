import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { getFontAwesomeIcons } from '@helpers/fontAwesome';

export default defineNuxtPlugin(nuxtApp => {
  library.add(getFontAwesomeIcons());
  nuxtApp.vueApp.use(FontAwesomeIcon, {
    config: {
      autoAddCss: false,
    },
  });
  nuxtApp.vueApp.component('FontAwesomeIcon', FontAwesomeIcon);
});
