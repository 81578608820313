export enum PersonRoleTypeEnum {
  INITIAL_PERSON = 'INITIAL_PERSON',
  ADVISOR = 'ADVISOR',
  INVESTOR = 'INVESTOR',
  FUND_INVESTOR = 'FUND_INVESTOR',
  BORROWER = 'BORROWER',
}

export enum RoleStatus {
  CONCEPT = 'CONCEPT',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum CompanyStatusEnum {
  PROSPECT = 'PROSPECT',
  KYC_PENDING = 'KYC_PENDING',
  ACTIVE = 'ACTIVE',
  STOPPED = 'STOPPED',
}

export enum CrmEntityTypeEnum {
  PERSON = 'PERSON',
  COMPANY = 'COMPANY',
}

export enum InvestorTypeEnum {
  ACTIVE = 'ACTIVE',
  CONCEPT = 'CONCEPT',
  INACTIVE = 'INACTIVE',
}

export enum RoleStatusEnum {
  CONCEPT = 'CONCEPT',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
