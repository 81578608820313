export const appBaseURL = "/"

export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[],"style":[],"script":[{"hid":"gtm","children":"(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n          })(window,document,'script','dataLayer','GTM-WDGLMCT');","type":"text/javascript"},{"hid":"hj","children":"(function(h,o,t,j,a,r){\n            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};\n            h._hjSettings={hjid:2577757,hjsv:6};\n            a=o.getElementsByTagName('head')[0];\n            r=o.createElement('script');r.async=1;\n            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;\n            a.appendChild(r);\n            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');","type":"text/javascript"}],"noscript":[]}

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const vueAppRootContainer = '#__nuxt'