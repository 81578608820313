import { defineStore, acceptHMRUpdate } from 'pinia';
import { computed, ref } from 'vue';
import { ME_QUERY } from '@graphql/queries/auth';
import { Person, MeResponse, Company, PersonRoles } from '@graphql/types/auth';
import { FundStatuses } from '@graphql/enums/fundStatuses';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { PersonRoleTypeEnum, RoleStatusEnum } from '@graphql/enums/auth';
import { createClient, OperationResult, ClientOptions, fetchExchange } from '@urql/core';

const clientOptions: ClientOptions = {
  url: import.meta.env.VITE_PROXY_URL ?? '',
  fetchOptions: {
    headers: {
      environment: import.meta.env.VITE_GRAPHQL_ENVIRONMENT,
    },
  },
  exchanges: [fetchExchange],
};

const gqlClient = createClient(clientOptions);

const storeSetup = () => {
  const user = ref<Person | null>(null);
  const accessToken = ref<string | null>(null);
  const refreshToken = ref<string | null>(null);
  const expiration = ref<number | null>(null);
  const activeFunderId = ref<number | string>(0);
  const isLoading = ref<boolean>(false);
  const isFromApp = ref<boolean>(false);

  const authenticated = computed(() => !isLoading.value && user.value !== null);
  const funders = computed(() => user.value?.roles ?? []);
  const fundInvestorStatus = computed(() => {
    const fundInvestor = user.value?.roles.find(
      role => role.type === PersonRoleTypeEnum.FUND_INVESTOR,
    );
    return fundInvestor?.status as FundStatuses | undefined;
  });

  const isInvestor = computed(() => {
    const investorDetails = user?.value?.roles.find(role => role.type === PersonRoleTypeEnum.INVESTOR);
    return investorDetails?.status === RoleStatusEnum.ACTIVE;
  });

  const isFundInvestor = computed(() => {
    const investor = user.value?.roles.find(role => role.type === PersonRoleTypeEnum.FUND_INVESTOR);
    return investor?.status === RoleStatusEnum.ACTIVE;
  });

  const isBorrower = computed(() => {
    return !!(user?.value?.roles.find(role => role.type === PersonRoleTypeEnum.BORROWER));
  });

  const onfidoChecked = computed(() => user.value?.onfido_checked);

  const shouldRefreshToken = computed((): boolean => {
    if (!refreshToken.value) return false;

    if (typeof accessToken.value === 'string') {
      const jwt = jwtDecode<JwtPayload>(accessToken.value);

      if (jwt?.exp && jwt?.exp < Date.now()) return false;
    }

    return true;
  });

  const updateTokens = (
    _accessToken: string | null,
    _refreshToken: string | null,
    _expiration: number | null,
  ) => {
    accessToken.value = _accessToken;
    refreshToken.value = _refreshToken;
    expiration.value = _expiration;
  };

  const login = (
    _user: Person,
    _accessToken: string,
    _refreshToken: string,
    _expiration: number,
  ) => {
    user.value = _user;
    updateTokens(_accessToken, _refreshToken, _expiration);
  };

  const logout = () => {
    user.value = null;
    updateTokens(null, null, null);
  };

  const setFunder = (id: number) => {
    activeFunderId.value = id;
  };

  const setIsFromApp = () => {
    isFromApp.value = true;
  };

  const refreshUser = async () => {
    return accessToken.value ? gqlClient
      .query<MeResponse>(ME_QUERY, undefined, {
        requestPolicy: 'network-only',
        fetchOptions: {
          cache: 'no-cache',
          headers: {
            authorization: `Bearer ${accessToken.value}`,
            environment: import.meta.env.VITE_GRAPHQL_ENVIRONMENT,
          },
        },
      })
      .toPromise()
      .then(async (result: OperationResult<MeResponse>) => {
        if (result.data?.me) {
          user.value = result.data.me;
        } else {
          accessToken.value = null;
          refreshToken.value = null;
          expiration.value = null;
        }
      })
      .catch(() => {
        accessToken.value = null;
        refreshToken.value = null;
        expiration.value = null;
      }) : null;
  };

  const queryUser = async (access: string, refresh: string, expiry: number) => {
    return gqlClient
      .query<MeResponse>(ME_QUERY, undefined, {
        requestPolicy: 'network-only',
        fetchOptions: {
          cache: 'no-cache',
          headers: {
            authorization: `Bearer ${access}`,
            environment: import.meta.env.VITE_GRAPHQL_ENVIRONMENT,
          },
        },
      })
      .toPromise()
      .then(async (result: OperationResult<MeResponse>) => {
        if (result.data?.me) {
          user.value = result.data.me;
          login(user.value, access, refresh, expiry);
          return user.value;
        }
      })
      .catch(() => {
        accessToken.value = null;
        refreshToken.value = null;
        expiration.value = null;
        return null;
      });
  };

  const nawData = computed(() => {
    const mainAddress = user.value?.addresses.find(adress => adress.main_address === true);
    const initialRole = user.value?.roles.find(role => role.type === PersonRoleTypeEnum.INITIAL_PERSON);

    return {
      zipcode: mainAddress?.postal_code ?? '',
      house_number: mainAddress?.house_number ?? '',
      addition: mainAddress?.house_number_addition ?? '',
      city: mainAddress?.city ?? '',
      street: mainAddress?.street ?? '',
      phone_numbers: initialRole?.role_data.phones ?? [],
      emails: initialRole?.role_data.emails ?? [],
    };
  });

  const nawCompleted = computed(() => {
    return nawData.value.zipcode.length > 0
      && nawData.value.house_number.length > 0
      && nawData.value.city.length > 0
      && nawData.value.street.length > 0
      && nawData.value.phone_numbers.length > 0
      && nawData.value.emails.length > 0;
  });

  const companies = computed(() => {
    return user.value?.companies;
  });

  const updateUser = (payload: Person) => {
    user.value = payload;
  };

  const hasIbans = computed(() => {
    let hasPersonalIbans = false;
    user.value?.roles.forEach((role: PersonRoles) => {
      if (role.role_data.ibans) {
        role.role_data.ibans.forEach(_iban => {
          hasPersonalIbans = true;
        });
      }
    });
    return hasPersonalIbans;
  });

  const myProfileNotificationCount = computed(() => {
    const array = [nawCompleted.value, hasIbans.value, onfidoChecked.value];
    return (array.length - array.filter(Boolean).length);
  });

  const getUserPhoneNumbers = () => user.value?.roles.filter(role => role.role_data.phones?.length > 0).map(role => role.role_data.phones[0].phone);

  const getPersonRole = (type: PersonRoleTypeEnum) => {
    return user.value?.roles.find(
      role => role.type === type,
    );
  };

  const getCompanyById = (id: string) => {
    return user?.value?.companies?.find((company: Company) => company?.id === id);
  };

  const guardedRoutes = computed(() => {
    const routes: [] = [];

    if (authenticated.value) {
      // routes.push({
      //   to: '/mijn-profiel',
      //   label: 'Mijn profiel',
      //   icon: 'address-card',
      //   notificationCount: myProfileNotificationCount,
      // });

      // if (isBorrower.value) {
      //   routes.push({
      //     to: '/mijn-aanvragen',
      //     label: 'Mijn aanvragen',
      //     icon: 'grid-2',
      //   });
      // }

      // routes.push({
      //   to: '/mijn-organisaties',
      //   label: 'Mijn organisaties',
      //   icon: 'grid-2',
      // });
    }
    return routes;
  });

  return {
    // State
    user,
    accessToken,
    refreshToken,
    expiration,
    activeFunderId,
    isLoading,
    isFromApp,

    // Getters
    authenticated,
    funders,
    fundInvestorStatus,
    isFundInvestor,
    isInvestor,
    isBorrower,
    shouldRefreshToken,
    getUserPhoneNumbers,
    getPersonRole,
    myProfileNotificationCount,
    hasIbans,
    nawCompleted,
    companies,
    guardedRoutes,
    nawData,
    onfidoChecked,

    // Actions
    login,
    logout,
    queryUser,
    setFunder,
    updateTokens,
    refreshUser,
    updateUser,
    getCompanyById,
    setIsFromApp,
  };
};

export const useAuthStore = defineStore('auth', storeSetup, {
  persistedState: {
    merge: (state, savedState) => {
      if (savedState.accessToken) {
        state.isLoading = true;

        gqlClient
          .query<MeResponse>(ME_QUERY, undefined, {
            requestPolicy: 'network-only',
            fetchOptions: {
              cache: 'no-cache',
              headers: {
                authorization: `Bearer ${savedState.accessToken}`,
                environment: import.meta.env.VITE_GRAPHQL_ENVIRONMENT,
              },
            },
          })
          .toPromise()
          .then((result: OperationResult<MeResponse>) => {
            if (result.data?.me) {
              state.user = result.data.me;
            } else {
              state.accessToken = null;
              state.refreshToken = null;
              state.expiration = null;
              state.activeFunderId = 0;
            }
          })
          .catch(() => {
            state.accessToken = null;
            state.refreshToken = null;
            state.expiration = null;
            state.activeFunderId = 0;
          })
          .finally(() => {
            state.isLoading = false;
          });
      }
      state.accessToken = savedState.accessToken ?? null;
      state.refreshToken = savedState.refreshToken ?? null;
      state.expiration = savedState.expiration ?? null;
      state.activeFunderId = savedState.activeFunderId ?? 0;

      return state;
    },
    excludePaths: ['user', 'isFromApp'],
  },
  share: {
    initialize: true,
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
