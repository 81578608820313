import { gql } from '@urql/core';

// On future changes, check IMPERSONATE_MUTATION below
export const LOGIN_MUTATION = gql`
  mutation ($input: LoginInput!) {
    login(input: $input) {
      access_token
      refresh_token
      expires_in
      user {
        id
        name
        onfido_status
        onfido_checked
        onfido_hash
        first_name
        last_name
        infix
        gender
        date_of_birth
        place_of_birth
        country_of_birth
        addresses {
          main_address
          street
          house_number
          house_number_addition
          postal_code
          city
        }
        documents {
          id
          key
          name
          records {
            asset {
              absolute_url
              created_at
              extension
              filename
              id
              updated_at
              filesize {
                raw
                human
              }
            }
            created_at
            id
            name
            updated_at
          }
          type
          visible_for_customer
        }
        documentContext {
          documentOwners {
            owner {
              ... on Person {
                id
                name
              }
              ... on Company {
                id
                name
                coc_number
                __typename
              }
            }
            items {
              id
              key
              name
              maxSize
              template {
                absolute_url
                created_at
                extension
                filename
                id
              }
              records {
                asset {
                  absolute_url
                  created_at
                  extension
                  filename
                  id
                  updated_at
                  filesize {
                    raw
                    human
                  }
                }
                downloadable_for_customer
                created_at
                id
                name
                updated_at
              }
              type
              visible_for_customer
            }
          }
          title
          fund_key
          show
        }
        roles {
          id
          type
          status
          account_view_ids
          role_data {
            ... on InitialPerson {
              ibans 
              emails {
                email
                type
              }
              phones {
                phone
                type
              }
            }
            ... on Investor {
              ibans
              emails {
                email
                type
              }
              phones {
                phone
                type
              }
            }
            ... on FundInvestor {
              ibans
              emails {
                email
                type
              }
              phones {
                phone
                type
              }
            }
            ... on Borrower {
              ibans
              emails {
                email
                type
              }
              phones {
                phone
                type
              }
            }
          }
        }
        funders {
          id
          status
          name
          type
          coc_number
          ibans
          ubo_count
          roles {
            id
            type
            status
          }
        }
      }
    }
  }
`;

// On future changes, check LOGIN_MUTATION above
export const IMPERSONATE_MUTATION = gql`
  mutation ($input: TokenLoginInput!) {
    tokenLogin(input: $input) {
      access_token
      refresh_token
      expires_in
      user {
        id
        name
        first_name
        last_name
        infix
        gender
        date_of_birth
        place_of_birth
        country_of_birth
        onfido_status
        onfido_checked
        onfido_hash
        addresses {
          main_address
          street
          house_number
          house_number_addition
          postal_code
          city
        }
        documents {
          id
          key
          name
          template {
            absolute_url
            created_at
            extension
            filename
            id
          }
          records {
            asset {
              absolute_url
              created_at
              extension
              filename
              id
              updated_at
              filesize {
                raw
                human
              }
            }
            downloadable_for_customer
            created_at
            id
            name
            updated_at
          }
          type
          visible_for_customer
        }
        documentContext {
          documentOwners {
            owner {
              ... on Person {
                id
                name
                __typename
              }
              ... on Company {
                id
                name
                coc_number
                __typename
              }
            }
            items {
              id
              key
              name
              maxSize
              records {
                asset {
                  absolute_url
                  created_at
                  extension
                  filename
                  id
                  updated_at
                  filesize {
                    raw
                    human
                  }
                }
                created_at
                id
                name
                updated_at
              }
              type
              visible_for_customer
            }
          }
          title
          fund_key
          show
        }
        roles {
          id
          type
          status
          account_view_ids
          role_data {
            ... on InitialPerson {
              ibans 
              emails {
                email
                type
              }
              phones {
                phone
                type
              }
            }
            ... on Investor {
              ibans
              emails {
                email
                type
              }
              phones {
                phone
                type
              }
            }
            ... on FundInvestor {
              ibans
              emails {
                email
                type
              }
              phones {
                phone
                type
              }
            }
            ... on Borrower {
              ibans
              emails {
                email
                type
              }
              phones {
                phone
                type
              }
            }
          }
        }
        funders {
          id
          status
          name
          type
          coc_number
          ibans
          ubo_count
          roles {
            id
            type
            status
          }
        }
      }
    }
  }
`;

export const REFRESH_TOKEN_MUTATION = gql`
  mutation ($input: RefreshTokenInput!) {
    refreshToken(input: $input) {
      access_token
      refresh_token
      expires_in
    }
  }
`;

export const LOGOUT_MUTATION = gql`
  mutation {
    logout {
      status
      message
    }
  }
`;

export const REQUEST_PASSWORD_RESET = gql`
  mutation ($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      message
      status
    }
  }
`;

export const UPDATE_FORGOTTEN_PASSWORD = gql`
  mutation ($input: UpdateForgottenPasswordInput!) {
    updateForgottenPassword(input: $input) {
      message
      status
    }
  }
`;
